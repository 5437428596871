<template>
  <!-- Header Part -->
  <nav class="navbar">
    <a class="sidebar-toggler">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-menu"
        @click="$emit('toggleSidebar')"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
      <img src="assets/images/logo.png" width="60" class="ms-2" />
    </a>

    <div class="navbar-content topbar_header_responsive_hp">
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'Faq_page' }" class="nav-link" target="_blank"><i
              class="fas fa-question-circle"></i></router-link>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle disabled"
            href="#"
            id="messageDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-comment-dots"></i>
            <img src="../../public/dashboard-assets/images/M2/massge.png" class="w-90" alt="">
          </a>
        </li> -->

        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle disabled"
            href="#"
            id="notificationDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-bell">
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <img src="../../public/dashboard-assets/images/M2/Notification.png" class="w-90" alt="">
            <div class="indicator">
              <div class="circle"></div>
            </div>
          </a>
        </li> -->
 <!-- Added by pmaps team -->
        <!-- Commented "Start tour" button since tour flow is not fixed yet. To be removed once tour is implemented and if this code is not 
        needed -->
        <!-- <li class="nav-item" v-if="componentName.slice(-4) !== 'Tour'">
          <a style="color: #6571ff; cursor: pointer" @click="showTour()">
            <i class="fa-regular fa-lg fa-paper-plane"></i>
            Start tour
          </a>
        </li> -->
        <li class="nav-item">
          <a href="https://loom.com/share/folder/668e08be05664594978b508e5d152520" target="_blank">          
            <img src="../../public/dashboard-assets/images/M2/loom.svg"
               height="70" width="70"
              title="Tutorial" style="cursor: pointer">
          </a>
        </li>
        <li class="nav-item" style="margin-right: 20px;" v-if="this.receivedData==true">
          <!-- How to get started? -->
          <span :class="{'inactive-effect': isInactive}" @mousemove="resetTimer" @keydown="resetTimer" @click='tourSelected'>
            <img src="../../public/dashboard-assets/images/M2/tutorialBulb.svg" class="align-tutorial-icon"
              height="70" width="70"
              title="Tutorial" style="cursor: pointer; width:35px; height: 35px;">
        </span>
        </li>
        <!-- Added by pmaps team -->
        <li class="nav-item" id="user-company-details">
          <div
            class="w-100"
            data-bs-toggle="modal"
            data-bs-target="#SidebarPartner"
          >
            <div class="profile-drop-kj">
              <div
                @click="invite"
                style="cursor: pointer"
                class="d-flex align-items-center"
              >
                <div class="PartnerProfile-area">
                  <div
                    class="PartnerProfile"
                    style="background-color: #000"
                    v-if="this.photo == null"
                  >
                    {{ companyName?.charAt(0) }}
                  </div>
                  <div class="profile" style="background-color: #000" v-else>
                    <img :src="this.photo" class="profile" />
                  </div>
                </div>
                <!-- <img src="../../public/dashboard-assets/images/M2/logo.png" class="h-100" alt=""> -->
                <div class="px-3 PartnerProfile-area_content">
                  <h6>{{ companyName }}</h6>
                  <small class="text-muted"> {{ this.isEnterprise==false ? this.planName + ' Plan' : 'Enterprise plan'}} </small>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav m-0" id="profile-button">
        <li class="nav-item dropdown me-4">
          <a
            class="nav-link dropdown-toggle float-end text-right"
            href="#"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            v-if="this.profilePhoto"
          >
            <img
              class="wd-30 ht-30 rounded-circle"
              :src="this.picList"
              alt="profile"
            />
          </a>
          <a
            class="nav-link dropdown-toggle float-end text-right profile-pic-art"
            href="#"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            v-else
          >
            {{ this.picList }}
          </a>
          <ul class="dropdown-menu shadow"  id="user-profile-actions">
            <li class="dropstart">
              <div class="dropdown_account_hover_hp w-100">
                <button class="dropbtn_account_hover_hp w-100">
                  Account
                  <span class="float-right"
                    ><i class="fa-solid fa-angle-down"></i
                  ></span>
                </button>
                <div class="dropdown-content_account_hover_hp w-100">
                  <router-link :to="{ name: 'Profile' }" class="dropdown-item">
                    Profile</router-link
                  >
                  <!-- <a class="dropdown-item disabled" href=""> Activity</a>
                  <a class="dropdown-item disabled" href=""> Billing plan</a> -->
                </div>
              </div>
            </li>

            <!-- <li><a class="dropdown-item disabled" href="#">Settings</a></li> -->
            <li>
                <a href="https://pmapstest.com/privacy-policy/" target="_blank" class="dropdown-item">Privacy policy</a>
            </li>
            <!-- <li>
              <a class="dropdown-item disabled" href="#">Terms & Conditions</a>
            </li>
            <li><a class="dropdown-item disabled" href="#">Help</a></li> -->
            <li>
              <a
                class="dropdown-item"
                style="cursor: pointer"
                @click="isLoggedout"
                >Logout</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <UserAccountPopup
    v-if="this.openInvite == true"
    @clicked="closeinvite"
    @getCompany="SwitchCompany"
  />
</template>

<script>
import UserAccountPopup from "../components/UserAccountPopup.vue";
import { mapState, mapActions, mapMutations } from "vuex";
// import eventBus from '@/components/TourComponents/eventBus.js'
import eventBus from '../components/TourComponents/eventBus.js'

// import { setTourClicked } from "../utils/helper";
// import { getNewUser } from "../utils/auth";
export default {
  name: "logout",
  components: {
    UserAccountPopup,
  },
  data() {
    return {
      errorMsg: "",
      openInvite: false,
      companyName: "",
      picList: "",
      profilePhoto: "",
      photo: null,
      billing: JSON.parse(localStorage.getItem('billingData')),
      isEnterprise: false,
      planName: '',
      isTourEnabled: false,
      componentName: "",
      isInactive: false,
      timeoutId: null,
      receivedData:false,
    };
  },
  created() {
    this.componentName = this.$route.name;
    // setTourClicked('false');
  },
  mounted() {
    eventBus.on('data-from-child-component', data => {
      this.receivedData=data;
    });
    this.startInactivityTimer();
    window.addEventListener('mousemove', this.resetTimer);
    window.addEventListener('keydown', this.resetTimer);

    this.isEnterprise=this.user.isEnterprise;

    if(this.isEnterprise==false){
      this.planName=this.billing.object.data.plan.planName;
    }
    
    setTimeout(async () => {
      var pic = await this.profilePic({
        userId: this.user.userId,
      });
      this.picList = pic.object.data.profilePic;
      if (this.picList.includes("https://")) {
        this.profilePhoto = true;
      }

      var company = await this.Companyheader({ clientId: this.user.clientId });
      this.companyName = company.object.data.companyName;
      
      if(this.companyName != null){
        localStorage.setItem("activeAccount", this.companyName);
      }

      var photo = company.object.data.profileImage;
      if (photo != null) {
        var resp = await this.companylogo({
          fileName: photo,
        });
        this.photo = resp.object.data;
      } else {
        this.photo = null;
      }

      localStorage.setItem("isParent", company.object.data.isParent);
    }, 100);
  },
  beforeUnmount() {
    eventBus.off('data-from-child-component'); 
    this.clearInactivityTimer();
    window.removeEventListener('mousemove', this.resetTimer);
    window.removeEventListener('keydown', this.resetTimer);
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
      clientId: (state) => state.auth.user.clientId,
    })
  },
  methods: {
    ...mapActions([
      "logout",
      "ShowWarningMessage",
      "Companyheader",
      "profilePic",
      "companylogo",
    ]),
    ...mapActions(["AllActiveTest"]),
    ...mapMutations(["setLoading"]),
    async isLoggedout() {
      this.isLoading = true;
      var data = await this.logout({ userId: this.userId });
      if (data.statusCode === 1012) {
        this.$router.push("/login");
      } else {
        // return this.ShowWarningMessage(data.msg.message);
      }
    },
    tourSelected(){
      const dataToSend = true;
      eventBus.emit('data-from-header-component', dataToSend);
    },
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.timeoutId = setTimeout(() => {
        this.isInactive = true;
      }, 3000); 
    },
    resetTimer() {
      this.isInactive = false;
      this.startInactivityTimer();
    },
    clearInactivityTimer() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    invite() {
      this.openInvite = true;
    },

    closeinvite(val) {
      this.openInvite = val;
    },

    SwitchCompany(val) {
      this.companyName = val.company;
      localStorage.setItem("activeAccount", this.companyName);
    },

    // showTour() {
    //   // setTourClicked('true');
    //   window.open("/test-library-tour");
    // }
  },
};
</script>

<style scoped>
/* .profile-pic-art {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #e2ddff;
  color: #6571ff !important;
  font-size: 20px;

  display: flex !important;
  align-items: center;
  justify-content: center;
}
.profile {
  width: 40px;
  height: 40px;
  border-radius: 100px;
} */

/* .inactive-effect::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  background: #F1F0FF;
  filter: blur(10px);
  z-index: -1;
  animation: glow-animation 2s infinite;
}

@keyframes glow-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
} */

/* .inactive-effect::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 100%;
  background: rgba(38, 0, 255, 0.3);
  animation: glow-animation 2s infinite;
} */

.inactive-effect::after {
  content: '';
  position: absolute;
  top: 1px;
  left: -10px;
  right: -10px;
  bottom: 1px;
  border-radius: 50%;
  /* background: #cfcbfa; */
  background: #4b2eee;

  animation: glow-animation 3s infinite;
}

@keyframes glow-animation {
  0% {
    opacity: 0.1;
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(1.4);
  }

  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

</style>
